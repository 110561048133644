<div class="dqp-toolbar">
    <ig-search-field class="ig-grow" (textChange)="filterEnvGroupTable($event)"
                     i18n-placeholder placeholder="Search Environment Groups..."
    ></ig-search-field>
</div>
<p-table #envGroupsTable [value]="envGroupsTableData" selectionMode="single"
         [(selection)]="selection"
         dataKey="principalId"
         class="ig-flex"
         styleClass="p-datatable-striped"
         [globalFilterFields]="['principalName']"
         [scrollable]="true"
>
    <ng-template pTemplate="header">
        <tr class="ig-plain">
            <th [pSortableColumn]="'principalName'" i18n>Environment Group Name
                <p-sortIcon [field]="'principalName'"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
        <tr [pSelectableRow]="rowData">
            <td>{{rowData.principalName}}</td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr class="empty-message"><td i18n>No environment groups available.</td></tr>
    </ng-template>
</p-table>